/* Overall page container—with space for fixed nav */
.item-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 60px;   /* Space for fixed nav when on top (desktop) */
  padding-bottom: 70px; /* Space for fixed nav when on bottom (mobile) */
  background-color: #f8f8f8;
  font-family: Arial, sans-serif;
}

/* Single Fixed Navigation Bar */
.fixed-nav {
  position: fixed;
  left: 0;
  width: 100%;
  background-color: #0078ae;
  color: #fff;
  z-index: 1000;
  padding: 10px 0;
  top: 0; /* Default: nav fixed at the top */
}

/* On smaller screens, fix the nav to the bottom */
@media (max-width: 767px) {
  .fixed-nav {
    top: auto;
    bottom: 0;
  }

    .item-page {
      background-color: #f8f8f8;
      display: flex;
      flex-direction: column;
      font-family: Arial, sans-serif;
      min-height: 100vh;
      padding-bottom: 70px;
  }
}

/* Navigation container inside the fixed nav */
.nav-container {
  max-width: 900px;  /* Wider than your card */
  margin: 0 auto;
  width: 100%;
}

/* Center nav section for category buttons */
.nav-center {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

/* Category button styles (used in nav-center) */
.category-button-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  flex: 1; /* Ensures even distribution */
}

.category-button {
  background-color: #fff;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0078ae;
  font-size: 18px;
}

.category-name {
  font-size: 12px;
  color: #fff;
  margin-top: 2px;
}

/* Main content card */
.item-card {
  max-width: 800px;
  width: 100%;
  margin: 20px auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

/* Header section of card */
.item-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.item-image img {
  width: 360px;
  height: 360px;
  object-fit: cover;
  border-radius: 10px;
}
.item-info {
  flex: 1;
  padding: 20px;
}
.item-info h2 {
  margin: 0 0 10px;
  font-size: 28px;
  color: #333;
}
.item-category {
  display: inline-block;
  background-color: #0078ae;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  margin-bottom: 10px;
}

/* Details Section */
.item-details {
  margin-top: 20px;
}
.details-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}
.detail-card {
  background-color: #f1f1f1;
  padding: 15px;
  border-radius: 8px;
}
.detail-card h4 {
  margin-top: 0;
  font-size: 16px;
  color: #0078ae;
}
.detail-card ul {
  list-style: disc;
  padding-left: 20px;
  margin: 10px 0 0;
}

/* Links Section */
.links-section {
  margin-top: 20px;
}
.links-section h4 {
  font-size: 18px;
  color: #0078ae;
}
.links-section ul {
  list-style: none;
  padding: 0;
}
.links-section li {
  margin: 5px 0;
}
.links-section a {
  color: #0078ae;
  text-decoration: none;
}
.links-section a:hover {
  text-decoration: underline;
}

/* Resort Map Button Styling */
.resort-map-button {
  background-color: #0078ae;
  border: none;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  padding: 15px 25px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  transition: background-color 0.3s ease;
  width: 100%;
  max-width: 300px;
  margin: 10px auto;
}

.resort-map-button:hover {
  background-color: #005f8e;
}

.resort-map-button i {
  font-size: 24px;
}

.resort-map-button span {
  display: block;
  font-weight: bold;
}
