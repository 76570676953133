.subscription-container {
    max-width: 1200px;
    margin: 40px auto;
    padding: 30px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .navigation-buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .nav-button {
    background-color: #0078ae;
    color: white;
    padding: 10px 16px;
    font-size: 16px;
    border-radius: 6px;
    border: none;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
  }
  
  .nav-button:hover {
    background-color: #005f8d;
  }
  
  .subscription-header {
    font-size: 32px;
    color: #0078ae;
    margin-bottom: 20px;
  }
  
  .subscription-content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 40px;
    width: 100%;
    max-width: 1200px;
  }
  
  .subscription-details {
    flex: 1;
    text-align: left;
    max-width: 500px;
  }
  
  .stripe-pricing-wrapper {
    flex: 1;
    text-align: center;
    max-width: 500px;
  }
  
  .plan {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  strong {
    color: #0078ae;
  }
  