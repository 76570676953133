.category-layout {
  display: flex;
  height: 100vh;
}

.sidebar {
  width: 20%;
  background-color: #0078ae;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px;
  color: white;
}

.sidebar-item {
  width: 100%;
  padding: 15px;
  margin: 5px 0;
  text-align: center;
  background-color: #005f8c;
  border: none;
  border-radius: 8px;
  color: white;
  cursor: pointer;
  font-size: 16px;
}

.sidebar-item.selected {
  background-color: #004d72;
}

.filter-bar {
  width: 20%;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.filter-input {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
}

.keyword-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: auto; /* Enable vertical scrolling */
  padding-right: 10px; /* Add padding for better spacing */
}

.keyword-item {
  padding: 8px;
  background-color: #e6e6e6;
  border-radius: 8px;
  text-align: center;
  font-size: 14px; /* Adjust font size for better visibility */
  cursor: pointer;
  transition: background-color 0.2s;
}

.keyword-item:hover {
  background-color: #d9d9d9; /* Add a hover effect for better interactivity */
}

.keyword-list::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.keyword-list::-webkit-scrollbar-thumb {
  background-color: #ccc; /* Color of the scrollbar thumb */
  border-radius: 4px; /* Rounded corners for the thumb */
}

.keyword-list::-webkit-scrollbar-thumb:hover {
  background-color: #b3b3b3; /* Darker color on hover */
}

/* Main content layout */
.main-content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Responsive grid */
  gap: 20px;
  padding: 20px;
  width: 100%; /* Ensure full width */
  box-sizing: border-box; /* Include padding in width */
  overflow-y: auto; /* Allow vertical scrolling */
}

/* Individual content card */
.content-card {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
  height: 314px; /* Allow the card to adapt to its content */
  display: flex;
  flex-direction: column;
}

.content-card:hover {
  transform: scale(1.05); /* Slightly enlarge on hover */
}

/* Poster container styling */
.poster {
  position: relative;
  width: 238px; /* Fixed width */
  height: 314px; /* Fixed height */
  background-color: #ccc; /* Fallback background */
  overflow: hidden;
  border-radius: 8px; /* Optional rounded corners */
  display: flex; /* Center content */
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
}

/* Poster image styling */
.poster-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Scale image to fully cover poster dimensions */
}

/* Overlay styling */
.poster-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3); /* Dark overlay with opacity */
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer; /* Change cursor to pointer */
}

/* Hover effect to show full overlay */
.poster:hover .poster-overlay {
  opacity: 1; /* Show overlay on hover */
  background: rgba(0, 0, 0, 0.8); /* Darker background for readability */
}

.poster-overlay:hover {
  height: 100%; /* Expand to full height on hover */
  justify-content: center; /* Center the content */
}

/* Poster title styling */
.poster-title {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  white-space: normal; /* Allow wrapping */
  word-wrap: break-word; /* Break long words */
  word-break: break-word; /* Ensure proper word breaks */
  overflow: hidden; /* Prevent overflow */
  text-overflow: ellipsis; /* Ellipsis for long text */
  line-height: 1.2; /* Adjust line height for readability */
  max-height: 3.6em; /* Limit to 3 lines of text */
  padding: 5px 10px; /* Add spacing around the text */
  width: 90%; /* Ensure the text doesn’t exceed overlay width */
  margin: 0; /* Reset margin */
}

/* Description styling */
.poster-description {
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
  opacity: 0; /* Hidden by default */
  transition: opacity 0.3s ease-in-out; /* Smooth fade-in effect */
  white-space: normal; /* Allow wrapping */
  word-wrap: break-word; /* Break long words */
  word-break: break-word; /* Ensure proper word breaks */
  max-height: 3em; /* Limit description height */
  overflow: hidden; /* Prevent overflow */
  color: #fff;
}

/* Show description on hover */
.poster-overlay:hover .poster-description {
  opacity: 1; /* Make description visible */
}

/* Learn More container styling */
.poster-learn-more {
  position: absolute;
  bottom: 20px; /* Pin closer to the bottom */
  left: 50%;
  transform: translateX(-50%); /* Center horizontally */
  opacity: 0; /* Hidden by default */
  transition: opacity 0.4s ease-in-out; /* Adjusted for quicker fade-in */
  text-align: center;
}

/* Button styling */
.poster-learn-more button {
  background-color: #0078ae; /* Modern blue background */
  color: white; /* White text for contrast */
  border: none; /* Remove default border */
  border-radius: 20px; /* Smooth, pill-like shape */
  padding: 8px 20px; /* Comfortable padding */
  font-size: 14px; /* Slightly smaller text */
  font-weight: bold; /* Bold text for emphasis */
  cursor: pointer; /* Pointer cursor on hover */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Add subtle shadow */
  transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth hover effects */
}

/* Hover effect */
.poster-learn-more button:hover {
  background-color: #005f8c; /* Slightly darker blue on hover */
  transform: translateY(-2px); /* Subtle lift effect */
}

/* Fade-in effect on hover */
.poster-overlay:hover .poster-learn-more {
  opacity: 1; /* Make the button visible */
}

.content-card:hover .poster-overlay {
  opacity: 1;
  transform: translateY(0); /* Slide into view */
}

.content-card:hover .poster-description {
  opacity: 1; /* Make description visible after delay */
}

.card-image {
  width: 100%;
  max-width: 300px;
  border-radius: 8px;
}

.card-title {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0;
}

.card-description {
  font-size: 14px;
  color: #666;
  text-align: center;
}

.active-keywords {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 10px;
}

.active-keyword {
  display: flex;
  align-items: center;
  background-color: #0078ae;
  color: white;
  padding: 5px 10px;
  border-radius: 16px;
  font-size: 14px;
}

.active-keyword .remove-keyword {
  background: none;
  border: none;
  color: white;
  margin-left: 8px;
  cursor: pointer;
  font-size: 14px;
}

.active-keyword .remove-keyword:hover {
  color: #ff4d4d;
}


/* ----- Fixed Navigation Bar ----- */
.fixed-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #0078ae;
  color: #fff;
  z-index: 1000;
  padding: 10px 0;
}
.nav-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nav-left,
.nav-center,
.nav-right {
  display: flex;
  align-items: center;
}
.nav-left .nav-home-button {
  background: none;
  border: none;
  color: #fff;
  font-size: 18px;
  margin-right: 15px;
  cursor: pointer;
}
.user-greeting {
  font-size: 16px;
}
.nav-center {
  flex: 1;
  justify-content: center;
}
.category-button-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 5px;
  cursor: pointer;
}
.category-button {
  background-color: #fff;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0078ae;
  font-size: 18px;
}
.category-name {
  font-size: 12px;
  margin-top: 4px;
  color: #fff;
}
.nav-right .nav-destination label {
  margin-right: 5px;
  font-size: 14px;
}
.nav-right .nav-destination select {
  padding: 5px;
  border-radius: 4px;
  border: none;
}

/* ----- Page & Content Layout ----- */
.category-page {
  padding-top: 70px; /* Leaves room for the fixed nav */
}
.content-layout {
  display: flex;
  gap: 20px;
  padding: 20px;
}
/* Filter bar at left */
.filter-bar {
  flex: 0 0 250px;
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
  height: calc(100vh - 100px);
  overflow-y: auto;
}
/* Main content grid */
.main-content {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  overflow-y: auto;
  padding-bottom: 20px;
}

/* (Keep your other existing styles for .filter-input, .active-keywords, 
   .keyword-item, .content-card, .poster, etc.) */

   @media (max-width: 767px) {
    /* Hide the desktop filter bar and nav-right on mobile */
    .filter-bar,
    .nav-right {
      display: none;
    }
  
    /* New Destination Row above the fixed nav */
    .destination-row {
      display: block;
      width: 100%;
      background-color: #0078ae;
      color: #fff;
      padding: 10px 20px;
      box-sizing: border-box;
      text-align: center;
    }
    .destination-row label {
      margin-right: 5px;
      font-size: 16px;
    }
    .destination-row select {
      padding: 5px;
      border-radius: 4px;
      border: none;
      font-size: 16px;
    }
  
    /* New Mobile Search Row above the fixed nav */
    .mobile-search-row {
      display: block;
      width: 100%;
      background-color: #fff;
      padding: 10px 20px;
      box-sizing: border-box;
    }
    .mobile-search-input {
      width: 100%;
      padding: 8px;
      font-size: 16px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
    /* Mobile keywords grid: 2 columns, max ~4 rows high */
    .mobile-keywords-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 8px;
      max-height: 10rem; /* Adjust as needed */
      overflow-y: auto;
      margin-top: 8px;
    }
    .mobile-keyword-item {
      padding: 8px;
      background-color: #e6e6e6;
      border-radius: 4px;
      text-align: center;
      font-size: 14px;
      cursor: pointer;
    }
  
    /* Show the filter bar as fixed at the bottom */
    .filter-bar {
      /* Override any previous desktop styles */
      display: block;
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 150px; /* Adjust height as needed (approx. 4 rows high) */
      z-index: 1010; /* Higher than the nav */
      overflow-y: auto;
      padding: 10px 20px;
      border-top: 1px solid #ccc;
      border-radius: 0;
      background-color: #f5f5f5;
    }

    /* Reposition the fixed nav to the bottom, behind the filter bar */
    .fixed-nav {
      position: fixed;
      bottom: 85px;
      left: 0;
      width: 100%;
      z-index: 10000; /* Lower than .filter-bar */
      padding: 10px 0;
    }

    /* Adjust the page’s bottom padding so that content isn’t hidden behind the fixed elements */
    .category-page {
      /* Increase bottom padding to account for filter bar and nav heights */
      padding-bottom: 170px; /* (e.g. 150px filter bar + 20px for the nav) */
      padding-top: 0px; /* (e.g. 150px filter bar + 20px for the nav) */
    }

    .keyword-list {
      display: flex;
      flex-direction: column;
      gap: 8px;
      overflow-y: auto;
      padding-right: 10px;
    }

    .filter-bar, .fixed-nav {
      bottom: 84px;
      left: 0;
      position: fixed;
      width: 100%;
      padding: 0px;
    }

    .filter-input {
      border: 1px solid #ccc;
      border-radius: 0px;
      margin: 0px;
      padding: 10px;
      position: fixed;
      width: 100%;
    }
    .poster {
      align-items: center;
      background-color: #ccc;
      border-radius: 8px;
      display: flex;
      height: 210px;
      justify-content: center;
      overflow: hidden;
      position: relative;
      width: 128px;
  }
  .main-content {
    display: grid;
    flex: 1 1;
    gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    overflow-y: auto;
    padding-bottom: 20px;
  }

  .content-card {
    border-radius: 8px;
    box-shadow: 0 4px 8px #0003;
    display: flex;
    flex-direction: column;
    height: 210px;
    overflow: hidden;
    position: relative;
    transition: transform .3sease;
  }

  .poster-description {
    display: none;
  }

  .poster-learn-more button {
      background-color: #0078ae;
      border: none;
      border-radius: 20px;
      box-shadow: 0 4px 6px #0003;
      color: #fff;
      cursor: pointer;
      font-size: 10px;
      font-weight: 700;
      padding: 8px 7px;
      transition: background-color .3sease, transform .3sease;
  }
  .active-keywords {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      margin-top: 40px;
  }
  .active-keyword {
    align-items: center;
    background-color: #0078ae;
    border-radius: 16px;
    color: #fff;
    display: flex;
    font-size: 10px;
    padding: 5px 10px;
  }

  .active-keyword .remove-keyword {
      background: none;
      border: none;
      color: #fff;
      cursor: pointer;
      font-size: 14px;
      margin-left: 8px;
      padding: 0px;
  }
}
  