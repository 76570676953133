/* Login.css */

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #0078ae; /* Main theme color */
  }
  
  .login-content {
    background-color: white;
    padding: 40px;
    border-radius: 15px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2),
                0px 4px 6px rgba(0, 0, 0, 0.15),
                0px 1px 3px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 400px;
    width: 100%;
  }
  
  .login-title {
    font-size: 24px;
    font-weight: bold;
    color: #005f8d; /* Slightly darker shade of the theme color */
    margin-bottom: 10px;
  }
  
  .login-subtitle {
    font-size: 16px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .login-button {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center them horizontally */
    gap: 10px; /* Add spacing between buttons */
  }
  