.getting-started-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
  }
  
  .options {
    display: flex;
    gap: 20px;
  }
  
  .option {
    cursor: pointer;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
  }
  
  .option img {
    width: 100px;
    height: 100px;
  }
  
  .option p {
    margin-top: 10px;
    font-size: 18px;
  }
  