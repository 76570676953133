.check-in-check-out-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
  }
  
  label {
    margin: 10px 0;
  }
  
  input {
    margin-left: 10px;
  }
  
  button {
    padding: 10px 20px;
  }
  
/* Fix for the left and right navigation buttons in DatePicker */
.react-datepicker__navigation {
  top: 8px !important; /* Adjusts the distance from the top of the calendar */
  z-index: 1 !important; /* Ensures the buttons are on top of other elements */
  width: 20px !important; /* Adjusts the width of the button */
  height: 20px !important; /* Adjusts the height of the button */
  background: none !important; /* Removes any background */
  border: none !important; /* Removes the border */
}

.react-datepicker__navigation--previous {
  left: 16px !important; /* Adjusts the left button positioning */
  margin: 0 !important;
}

.react-datepicker__navigation--next {
  right: 16px !important; /* Adjusts the right button positioning */
  margin: 0 !important;
}
