.select-resort-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
  }
  
  .resorts {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .resort-card {
    cursor: pointer;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
  }
  