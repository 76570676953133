#text {
  font-size: 16px;
  line-height: 1.5;
}
/* Container for the entire screen */
.itinerary-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden; /* Prevent unwanted scrolls */
  width: 100%;
  box-sizing: border-box;
  z-index: 1;
  position: relative;
}

.top-bar-center {
  flex: 1; /* Ensures the center div takes equal space */
  text-align: center; /* Centers the text */
}
.itinerary-name-top-bar {
  font-size: 1.5em; /* Adjust font size as needed */
  margin: 0;
  color: #FFF;
}

.add-item-button:disabled {
  background-color: #28a745;; /* Example: grey background */
  cursor: not-allowed; /* Example: change cursor to indicate it's disabled */
}

.card-top {
  margin-bottom: 13px;
  position: sticky;
  top: 0; /* Sticks the element at the top of its container */
  background-color: #0078ae; /* Ensure the background covers content behind */
  z-index: 10; /* Ensure it appears above other content in the container */
  padding: 10px; /* Maintain the existing padding */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for visual separation */
  color: rgb(255, 255, 255);
  text-align: center;
}

.demo-mode-bar {
  width: 100%;
  height: 33px;
  background-color: #ff6700; /* Dark background color */
  color: rgb(0, 0, 0);
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  margin-bottom: 51px;
  z-index: 1000; /* Ensure it stays above other elements */
}

.swipeable-views-container {
  height: calc(100vh - 60px); /* Ensure it takes full height minus the top-bar */
  overflow-x: hidden;
  overflow-y: auto; /* Allow vertical scrolling inside swipeable views */
  z-index: 1;
  position: relative;
}

.swipeable-views-slide {
  display: flex;
  flex-direction: column;
  height: 100%; /* Make sure each slide takes the full height */
  overflow-y: auto; /* Enable vertical scrolling inside each slide */
  margin-bottom: 30px;
}

/* Styling for tabs in the top-bar */
.tab {
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-weight: bold;
  padding: 10px;
  background-color: #0078ae;
  cursor: pointer;
  transition: background-color 0.3s;
}

/* Relative top-bar with CSS Grid for dynamic layout */
.top-bar {
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-template-rows: auto;
  grid-auto-rows: minmax(39px, auto);
  grid-auto-flow: row dense;
  background-color: #0078ae;
  z-index: 100; /* Ensure top-bar is above other elements */
  position: sticky;
  top: 0;
  overflow-x: hidden;
}

/* Bottom navigation bar */
.bottom-bar {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 6vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #0078ae;
  z-index: 10;
}

/* Styling for tabs in the top-bar */
.top-bar .tab {
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-weight: bold;
  padding: 10px;
  background-color: #0078ae;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.top-bar .tab.active {
  background-color: #005f8d;
}

.preview-label {
  text-align: center;
  font-weight: bold;
  margin-top: 5px;
}


.schedule-container-preview {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-position: center;
  border-radius: 10px;
  overflow: hidden;
}

/* Adjusted container for the schedule, occupying the full height between top and bottom bars */
.schedule-container {
  flex-grow: 1; /* Allows the container to grow and occupy available space */
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap; /* Ensure items don't wrap horizontally */
  width: 100%;
  overflow-x: hidden; /* Prevent horizontal overflow */
  max-width: 100%;
  box-sizing: border-box;
}

/* Container for the cards, arranged horizontally */
.cards-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  scroll-snap-type: x mandatory; /* Enable snapping to each card */
  overflow-x: scroll; /* Allow horizontal scrolling */
  height: 100%; /* Occupy full height */
}

/* Individual card styling */
.card {
  min-width: 100%; /* Each card takes up the full width of the viewport */
  scroll-snap-align: start; /* Snap alignment for smooth scrolling */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;
  border-radius: 20px;
}

/* Each time slot */
.time-slot {
  position: relative;
  margin-bottom: 10px;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Floating effect for each slot */
  width: 100%; /* Ensure the time slot takes up the full width of the card */
  display: flex;
  flex-direction: column; /* Stack content vertically */
  max-width: 100%;
  box-sizing: border-box;
}

/* Background image as an <img> */
.time-slot-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the container while maintaining aspect ratio */
  position: absolute; /* Allows content to overlay the image */
  top: 0;
  left: 0;
  z-index: 0; /* Ensures the image stays behind the content */
  border-radius: 20px; /* Ensures the image follows the slot's rounded corners */
}

/* Content within the time slot */
.time-slot-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  border-radius: 20px 20px 0 0; /* Rounded corners at the top */
  height: 143px;
  position: relative; /* Keep content above the image */
  z-index: 1; /* Brings content above the image */
}

/* Time display */
.time-box {
  font-size: 1.2em;
  font-weight: bold;
  background-color: rgb(255, 255, 255);
  padding: 5px 10px;
  border-radius: 10px;
}

/* Meatball menu icon */
.meatball-menu {
  font-size: 1.5em;
  color: #333;
  padding: 5px;
  background-color: rgb(255, 255, 255); /* White background for the meatball menu */
  border-radius: 50%; /* Make it circular */
  cursor: pointer;
}

/* Caption bar containing the caption and expanded section */
.caption-bar {
  background-color: rgb(255, 255, 255);
  border-radius: 0 0 20px 20px; /* Rounded corners at the bottom */
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow effect */
  padding: 10px;
  display: flex; /* Enables flexbox layout */
  align-items: center; /* Vertically centers the textarea */
  justify-content: center; /* Optionally, horizontally center the textarea */
  z-index: 0;
}

.caption-bar textarea {
  width: 100%;
  border-radius: 5px;
  border: none;
  font-size: 1em;
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  display: block; /* Ensures the textarea is treated as a block element */
  padding: 0; /* Remove padding if it's adding to the misalignment */
  line-height: 1.5; /* Adjust the line height for vertical centering */
}

/* Caption under the image */
.caption {
  font-size: 1em;
  text-align: center;
}

.meatball-menu {
  font-size: 1.5em;
  color: #333;
  padding: 5px;
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  cursor: pointer;
  position: relative; /* Make it the reference point for .expanded-section */
}

/* Slide-in animation */
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(10px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Expanded section that appears to the left */
.expanded-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  right: 100%;
  transform: translateX(-10px);
  white-space: nowrap;
  gap: 10px;
  animation: slideIn 0.2s ease-out; /* Apply animation */
}

/* Button styles */
.expanded-section .trash-can,
.expanded-section .edit-button {
  cursor: pointer;
  text-align: center;
  border: 0;
  background-color: rgb(255, 255, 255);
  padding: 4px;
}

.expanded-section .trash-can {
  color: #f93333;
}

.expanded-section .edit-button {
  color: #0078ae;
}

.mobile-add-item-button,
.mobile-my-itineraries,
.hamburger-icon {
  background: none;
  border: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

/* Floating action button */
.floating-action-button {
  position: fixed;
  bottom: 10vh;
  right: 20px;
  background-color: #0078ae;
  color: rgb(255, 255, 255);
  border: none;
  border-radius: 50%;
  width: 56px;
  height: 56px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  display: none; /* Commented out for now */
}

/* Demo Time slot */
.demo-time-slot {
  background-color: #f0f0f0;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 10px;
}

.demo-time-box {
  font-size: 24px;
  font-weight: bold;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
}

.demo-meatball-menu {
  font-size: 24px;
  cursor: pointer;
  padding: 10px;
}

.demo-caption-bar {
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
}


.category-buttons button {
  width: 50px;
  height: 50px;
  background-color: #007bff;
  color: rgb(255, 255, 255);
  border: none;
  font-size: 16px;
}

.category-buttons {
  display: flex;
  justify-content: center;
}

.category-button-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.category-button {
  width: 50px;
  height: 50px;
  background-color: #007bff;
  color: rgb(255, 255, 255);
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  font-size: 24px; /* Ensure icon is visible */
}

.category-name {
  font-size: 14px;
  color: #333;
  text-align: center;
}

.selected .category-button {
  background-color: #28a745; /* Change color when selected */
}

.modal-comment label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.modal-comment textarea {
  width: 100%;
  height: 60px;
  border-radius: 5px;
  border: none;
  font-size: 1em;
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.modal-time {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.react-datepicker-popper {
  position: fixed !important;
  line-height: 0 !important;
}

.react-datepicker-wrapper {
  display: inline-block;
}

.react-datepicker {
  font-size: 16px;
}

.react-datepicker__time-container {
  width: 120px;
}

.react-datepicker__time-list-item {
  padding: 8px;
}

.edit-icon {
  margin-left: 10px;
  color: #007bff;
}

.time-display {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.react-time-picker {
  margin-top: 10px;
  display: block;
}

.modal-time label {
  margin-bottom: 10px;
  font-weight: bold;
}

.time-picker-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 20px; /* Increase the size of the time */
  position: relative; /* Ensure the container can handle z-index */
  overflow: visible; /* Ensure the dropdown doesn't get clipped */
}

.time-picker-input {
  border: none;
  border-radius: 5px;
  width: 120px; /* Adjust width as needed */
  text-align: center;
  font-size: 24px; /* Larger font size for the time */
  font-weight: 700;
  position: relative; /* Positioning to ensure z-index works */
  z-index: 10; /* Ensure the input appears in front of other elements */
  margin-left: 0px;
}

.time-picker-input:focus {
  z-index: 1000; /* Bring input to the front when focused */
}

.modal-actions {
  display: flex;
  justify-content: space-between;
}

.modal-actions button {
  width: 45%;
  padding: 10px;
  font-size: 18px;
  border: none;
  border-radius: 5px;
}

.modal-actions button:first-child {
  background-color: #ccc;
}

.modal-actions button:last-child {
  background-color: #007bff;
  color: rgb(255, 255, 255);
}

.modal-select-category {
  padding-top: 10px;
}

.modal-select-item {
  padding-top: 10px;
}


/* Modal styling */
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  border-radius: 10px;
  width: 90%;
  max-width: 500px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

/* Top part of the modal with demo content */
.modal-top {
  background-color: #fff;
  padding: 10px;
  margin-bottom: 20px;
}

/* Bottom part of the modal with form content */
.modal-bottom {
  display: flex;
  flex-direction: column;
}

.modal-content h2 {
  margin-top: 0;
}

.loading-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0078ae; /* Blue background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it appears on top of other content */
}

.modal-content-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgb(255, 255, 255); /* White text color for better contrast */
  text-align: center;
}

.loading-logo {
  width: 150px;
  height: 150px;
  margin-bottom: 20px;
}

.loading-text {
  font-size: 24px;
  color: rgb(255, 255, 255);
  font-weight: bold;
  animation: pulse 1.5s infinite; /* Pulse animation */
}

/* Pulse animation for the loading text */
@keyframes pulse {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

.add-item-modal label {
  display: block;
  margin-bottom: 10px;
}

.add-item-modal input,
.add-item-modal select {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.time-selectors {
  display: flex;
  justify-content: space-between;
}

.time-selectors label {
  flex: 1;
  margin-right: 10px;
}

.time-selectors label:last-child {
  margin-right: 0;
}

/* Styles for itinerary-park */
.itinerary-park {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background-size: cover;
  background-position: center;
  z-index: 2;
}

.itinerary-park::before {
  content: "";
  position: absolute;
  top: -20%;
  left: 0;
  width: 100%;
  height: 20%;
  background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.5));
  z-index: 1;
}

/* Time label styling */
.time-label {
  display: block;
  margin-bottom: 20px;
}

.time-text {
  font-size: 24px;
  cursor: pointer;
}


button[type="submit"] {
  background-color: #0078ae;
  color: #fff;
}

/* Item type selectors */
.item-type-selectors {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  margin-bottom: 20px;
}

.item-type-option {
  display: flex;
  justify-content: center;
  align-items: center;
}

.item-type-option input[type="radio"] {
  display: none;
}

.item-type-box {
  width: 60px;
  height: 60px;
  background-color: #0078ae;
  border: 2px solid transparent;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: #fff;
  transition: border-color 0.3s;
}

.item-type-option input[type="radio"]:checked + .item-type-box {
  border-color: #005f8d;
  background-color: #005f8d;
}

.search-bar {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin: 0; /* Remove margin */
  box-sizing: border-box; /* Ensure padding doesn't affect width */
  font-size: 16px;
  display: flex;
  align-items: center;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.search-results {
  background: rgb(255, 255, 255);
  border: 1px solid #ccc;
  border-top: none; /* Remove border overlap */
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
}

.search-result-item {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-result-item:hover {
  background-color: #f0f0f0;
}

.search-result-item .plus-icon {
  font-size: 16px;
  color: #007bff; /* Example color for the icon */
}

.selected-indicator {
  margin-top: 10px;
  font-weight: bold;
  color: #28a745; /* Example color for success */
  text-align: center;
}

/* Style for the desktop container */
.desktop-container {
  min-height: 100vh;
  width: 100%;
  max-width: 100%; /* Prevent overflow */
  margin: 0 auto; /* Center the content */
  padding: 0 20px; /* Add some padding to the sides */
  box-sizing: border-box;
  overflow-x: auto; /* Enable horizontal scrolling if necessary */
}

/* Style for the cards inside the container */
.desktop-card {
  background-color: rgb(255, 255, 255);
  border-radius: 15px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2),
              0px 4px 6px rgba(0, 0, 0, 0.15),
              0px 1px 3px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  margin-bottom: 20px;
  margin-top: 10px;
  padding: 15px;
  position: relative;
  z-index: 2; /* Ensure the card is above other elements when hovered */
  width: 100%;
  height: max-content; /* Allow height to be determined by content */
  display: flex;
  flex-direction: column;
  min-height: 600px;
}

.desktop-card:hover {
  transform: translateY(-10px); /* Lift the card when hovered */
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.25),
              0px 10px 10px rgba(0, 0, 0, 0.2),
              0px 4px 6px rgba(0, 0, 0, 0.15);
  z-index: 3; /* Further increase the z-index on hover to ensure it stays on top */
}

/* Desktop-specific top bar */
.desktop-top-bar {
  display: flex;
  flex-direction: column;
  background-color: #005c86;
  color: rgb(255, 255, 255);
  padding: 10px 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 15px; /* Match the container's rounded corners */
}

.top-bar-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 5px 0;
}

.top-row {
  height: 64px; /* Adjust height for the top row */
}

.bottom-row {
  justify-content: center; /* Center the buttons in the bottom row */
  height: 39px; /* Adjust height for the bottom row */
}

.top-bar-left {
  display: flex;
  align-items: center;
}

.logo-name {
  display: flex;
  align-items: center;
  box-shadow: 0px 11px 21px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  padding: 10px 20px;
  background-color: #006999;
}

.login-button {
  background-color: #218838; /* Green background for login button */
  color: rgb(255, 255, 255);
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0px 11px 21px rgba(0, 0, 0, 0.2);
}

.login-button:hover {
  background-color: #1e7e34; /* Darker green on hover */
}


.app-logo {
  width: 32px;
  height: 32px;
  margin-right: 10px;
}

.app-name {
  font-size: 20px;
  font-weight: bold;
}

.top-bar-right {
  position: relative;
  display: inline-block;
  align-items: center;
  box-shadow: 0px 11px 21px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  padding: 10px 20px;
  background-color: #006999;
}

/* Adjust user greeting for better alignment */
.user-greeting {
  cursor: pointer; /* Show that it's clickable */
  border-radius: 5px;
  color: rgb(255, 255, 255);
  display: inline-flex;
  align-items: center;
}

.user-greeting i {
  margin-left: 5px;
}

.dropdown-menu {
  position: absolute;
  top: 100%; /* Aligns the dropdown menu right under the user greeting */
  left: 0; /* Aligns it to the left side of the parent container */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
  border-radius: 15px;
  width: 100%;
}

/* Optional: Additional styling for the dropdown button */
.logout-button {
  background-color: #e766b9;
  color: rgb(255, 255, 255);
  border: none;
  padding: 10px;
  border-radius: 15px;
  cursor: pointer;
  width: 100%; /* Make it full-width within the dropdown */
}

.logout-button:hover {
  background-color: #d31aa2;
}
.top-bar-buttons {
  display: flex;
  justify-content: space-between;
  min-height: 50px;
}

.top-bar-buttons button {
  background: none;
  border: none;
  color: rgb(255, 255, 255);
  font-size: 14px;
  margin: 0 10px; /* Adjust the space between buttons */
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
  box-shadow: 0px 11px 21px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  padding: 10px 20px;
  background-color: #006999;
}

.coffee-button {
  box-shadow: 0px 11px 21px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  padding: 10px 20px;
  background-color: #006999;
}

.top-bar-buttons button i {
  margin-right: 5px;
  font-size: 18px;
}

.top-bar-buttons button:hover {
  color: #c7edff; /* Lighten the button text color on hover */
}

/* Desktop-specific bottom bar */
.desktop-bottom-bar {
  margin-top:10px;
  display: flex;
  flex-direction: column;
  background-color: #005c86;
  color: rgb(255, 255, 255);
  padding: 10px 20px;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 15px; /* Match the container's rounded corners */
}

.bottom-bar-row {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 5px 0;
}

.first-row a, .second-row a {
  color: rgb(255, 255, 255);
  font-size: 14px;
  margin: 0 10px; /* Space between links */
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

.first-row a:hover, .second-row a:hover {
  color: #c7edff; /* Lighten the link color on hover */
}

.second-row i {
  font-size: 18px;
}

.add-item-bar {
  position: sticky;
  top: 0; /* Stick to the top of its parent container */
  margin-bottom: 10px; /* Add some space between the sticky bar and the content below */
  display: flex;
  justify-content: space-between; /* Space out the date and button */
  align-items: center;
  padding: 10px 15px; /* Add some padding for better spacing */
  background-color: #0078ae; /* Ensure the background covers content behind */
  color: rgb(255, 255, 255);
  z-index: 10; /* Ensure it appears above other content in the container */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for visual separation */
}


.social-icon {
  color: rgb(255, 255, 255);
  font-size: 20px;
  margin: 0 10px; /* Space between icons */
  transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.social-icon:hover {
  color: #c7edff; /* Lighten the icon color on hover */
  transform: scale(1.1); /* Slightly enlarge the icon on hover */
}

.social-icon i {
  font-size: inherit; /* Ensure icon size is consistent with the parent */
}

.mobile-full-date {
  position: sticky;
  top: 0; /* Stick to the top of its parent container */
  margin-bottom: 10px; /* Add some space between the sticky bar and the content below */
}

.subscription-field label {
  margin-bottom: 5px;
}

.subscription-field input[type="text"],
.subscription-field input[type="email"],
.subscription-field input[type="password"] {
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.subscription-field button {
  margin-top: 10px;
}

.mobile-modal-button {
  margin: 13px;
  padding: 13px;
  background-color: #0078ae;
  color: rgb(255, 255, 255);
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.logout-mobile-button {
  background-color: #d31aa2;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  width: 100%; /* Make it full-width within the dropdown */
  max-width: 100px;
  color: rgb(255, 255, 255);
  margin: 13px;
  padding: 13px;
}

.upgrade-button {
  margin-top: 10px;
  padding: 10px;
  background-color: #0078ae;
  color: rgb(255, 255, 255);
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.upgrade-button:hover {
  background-color: #005f8d;
}

.button-matrix {
  display: flex; /* Use flexbox for layout */
  justify-content: space-evenly; /* Evenly distribute buttons with equal spacing */
  align-items: center; /* Center buttons vertically */
  padding: 10px 0; /* Optional padding for spacing */
  gap: 10px; /* Space between buttons (optional if justify-content doesn't suffice) */
}

.button-matrix button {
  flex: 1; /* Ensure buttons grow evenly */
  max-width: 120px; /* Optional: Cap button width */
  text-align: center; /* Center the content inside the buttons */
  font-size: 18px;
  padding: 10px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  border: solid 1px #cecece;
}

.lock-button {
  background-color: #0078ae;
  color: #fff;
}

.lock-button:hover {
  background-color: #005f8d;
}

.cog-button {
  background-color: #9e9e9e;
  color: #fff;
}

.cog-button:hover {
  background-color: #e9e9e9;
}

.copy-button {
  background-color: #0078ae;
  color: #fff;
}

.copy-button:hover {
  background-color: #005f8d;
  color: #000;
}

.view-button {
  background-color: #28a745;
  color: #fff;
}

.view-button:hover {
  background-color: #218838;
}

.mobile-explore-catalog {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 24px;
  margin: 0;
  padding: 0;
}

/* Mobile-specific adjustments */
@media (max-width: 768px) {

  .swipeable-views-container {
      width: 100%;
      height: calc(100vh - 60px); /* Adjust height for smaller screens */
  }

  .itinerary-name {
    font-size: 18px;
    white-space: normal !important; /* Allow text to wrap */
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    font-weight: bold !important;
    color: #333 !important;
    text-align:left !important;
  }

  .gray-date {
    color: #919191;
  }
  
  .itinerary-dates {
    display: flex !important;
    flex-direction: column !important; /* Stack the dates vertically */
    font-size: 0.9rem !important; /* Adjust font size for mobile */
  }

  .button-group {
    display: flex !important;
    justify-content: space-between !important;
    align-items: normal !important;
    padding: 15px 1px !important;
  }
}


@media (min-width: 769px) {

  .lock-button {
    background: none;
    cursor: pointer;
    font-size: 20px;
    color: #0078ae;
    padding: 4px 20px;
    border: 1px solid #ddd;
    border-radius: 5px 5px 5px 5px;
    cursor: pointer;
  }
  
  /* Cog Button (left side) */
  .cog-button {
    padding: 10px 20px;
    background-color: #9e9e9e; /* Dark pink color */
    color: rgb(255, 255, 255);
    border: none;
    border-radius: 5px 0 0 5px;
    cursor: pointer;
  }
  
  .cog-button:hover {
    background-color: #e9e9e9; /* Darker pink on hover */
  }
  
  /* Copy Button (right side) */
  .copy-button {
    padding: 10px 20px;
    background-color: #0078ae;
    color: rgb(255, 255, 255);
    border: none;
    border-radius: 0;
    cursor: pointer;
  }

  .copy-button:hover {
    background-color: #005f8d;
    color: rgb(0, 0, 0);
  }
  
  /* Copy Button (right side) */
  .view-button {
    padding: 10px 20px;
    background-color: #28a745;
    color: rgb(255, 255, 255);
    border: none;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
  }
  
  .view-button:hover {
    background-color: #218838;
  }
  
  /* Container to ensure buttons appear connected */
  .button-group {
    display: inline-flex;
    align-items: center;
    padding: 8px 0px;
  }

  .demo-mode-bar {
    width: 100%;
    height: 33px;
    background-color: #ff6700; /* Dark background color */
    color: rgb(0, 0, 0);
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 0;
    z-index: 1000; /* Ensure it stays above other elements */
    margin: 0px;
  }
  
  .swipeable-views-slide {
    padding-right: 10px; /* Add margin between cards only on desktop */
  }
  
  /* Container for the entire screen */
  .itinerary-container {
    display: flex;
    flex-direction: column;
    height: 100vh; /* Ensure it takes full viewport height */
    overflow: hidden; /* Prevent unwanted scrollbars */
    background-color: #0078ae;
  }

  .add-item-button {
    background-color: #28a745;
    color: rgb(255, 255, 255);
    border: none;
    padding: 8px 15px;
    border-radius: 5px;
    font-size: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 0px 11px 21px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
  }

  .add-item-button i {
    margin-right: 5px; /* Space between the icon and text */
  }

  .add-item-button:hover {
    background-color: #288d3a;
  }
}


/* Step 4: Explicitly set slide width and adjust container overflow */
@media (min-width: 769px) {
  .top-bar {
    display: none; /* Hide the top bar on desktop */
  }

  .bottom-bar {
    display: none; /* Hide the bottom bar on desktop */
  }

  .swipeable-views-container {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto; /* Allow horizontal scrolling */
    box-sizing: border-box; /* Include padding in the width calculation */
    width: 100%; /* Ensure the container doesn't exceed the viewport width */
  }

  .swipeable-views-slide {
    min-width: 320px; /* Set a minimum width for each card */
    max-width: 320px; /* Set a maximum width for each card */
    width: 320px; /* Explicitly set the width to avoid stretching */
    flex: 0 0 auto; /* Prevent cards from stretching */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add some shadow for desktop */
    border-radius: 10px; /* Add rounded corners for desktop */
    background-color: rgb(255, 255, 255); /* Ensure the background is visible */
    overflow: hidden; /* Prevent content from overflowing */
  }

  /* Scroll Bar Customization for Desktop */
  .swipeable-views-container::-webkit-scrollbar {
    height: 8px; /* Custom scrollbar height */
  }

  .swipeable-views-container::-webkit-scrollbar-thumb {
    background-color: rgba(0, 120, 174, 0.8); /* Custom scrollbar color */
    border-radius: 4px; /* Round scrollbar edges */
  }
}

.url-container {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.url-display {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px 0 0 5px;
  font-size: 16px;
}

.lock-button {
  background: none;
  cursor: pointer;
  font-size: 20px;
  color: #0078ae;
  padding: 4px 20px;
  border: 1px solid #ddd;
  border-radius: 5px 5px 5px 5px;
  cursor: pointer;
}

/* Cog Button (left side) */
.cog-button {
  padding: 10px 20px;
  background-color: #9e9e9e; /* Dark pink color */
  color: rgb(255, 255, 255);
  border: none;
  border-radius: 5px 0 0 5px;
  cursor: pointer;
}

.cog-button:hover {
  background-color: #e9e9e9; /* Darker pink on hover */
}



/* Copy Button (right side) */
.copy-button {
  padding: 10px 20px;
  background-color: #0078ae;
  color: rgb(255, 255, 255);
  border: none;
  border-radius: 0;
  cursor: pointer;
}

.copy-button:hover {
  background-color: #005f8d;
  color: rgb(0, 0, 0);
}

/* Copy Button (right side) */
.view-button {
  padding: 10px 20px;
  background-color: #28a745;
  color: rgb(255, 255, 255);
  border: none;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
}

.view-button:hover {
  background-color: #218838;
}

/* Container to ensure buttons appear connected */
.button-group {
  display: inline-flex;
  align-items: center;
  padding: 8px 0px;
}

.login-header {
  font-size: 24px;
  font-weight: bold;
  color: #0078ae; /* Blue color for the header */
  margin-bottom: 10px;
}

.login-description {
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
}

.login-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-button {
  background-color: #d6d8db; /* Light gray background for close button */
  color: #333; /* Darker text color */
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.close-button:hover {
  background-color: #bfc2c5; /* Slightly darker gray on hover */
}

.modal-header {
  font-size: 24px;
  font-weight: bold;
  color: #0078ae; /* Blue color for the header */
  margin-bottom: 10px;
}

.modal-description {
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 13px;
}

.modal-close-button {
  background-color: #d6d8db; /* Light gray background for close button */
  color: #333; /* Darker text color */
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.modal-close-button:hover {
  background-color: #bfc2c5; /* Slightly darker gray on hover */
}

.itinerary-header {
  font-size: 24px;
  font-weight: bold;
  color: #0078ae; /* Blue color for the header */
  margin-bottom: 4px;
}

.itinerary-table {
  max-height: 438px; /* Set maximum height for the container */
  overflow-y: auto; /* Enable vertical scrolling when content exceeds max-height */
  border: 1px solid #ddd; /* Optional: Add a border for better visibility */
  padding: 10px; /* Optional: Add padding inside the container */
  background-color: #d9d9d9; /* Optional: Set a subtle background color */
}

.itinerary-table::-webkit-scrollbar {
  width: 8px; /* Customize the scrollbar width */
}

.itinerary-table::-webkit-scrollbar-thumb {
  background-color: #cccccc; /* Customize the scrollbar thumb color */
  border-radius: 4px; /* Round the scrollbar edges */
}

.itinerary-table::-webkit-scrollbar-track {
  background-color: #f5f5f5; /* Customize the scrollbar track color */
}


.itinerary-row {
  display: table-row;
  border-bottom: 1px solid #ddd;
}

.itinerary-row-header {
  display: table-row;
  margin: 10px 0;
}

.lock-button .fas {
  transition: color 0.3s;
}

.lock-button .fas.fa-lock {
  color: #dc3545; /* Red for unlocked */
}

.lock-button .fas.fa-unlock {
  color: #28a745; /* Green for locked */
}

.lock-color {
  color: #dc3545; /* Red for unlocked */
}

.unlock-color {
  color: #28a745; /* Green for locked */
}

.itinerary-name {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  text-align:left;
}

.itinerary-dates {
  font-size: 0.9rem;
}

.date-label {
  font-weight: normal;
}

.date-value {
  font-weight: bold;
  margin-right: 10px;
}

.date-separator {
  margin: 0 10px;
  color: #0078ae; /* Optional: Add some color to the separator */
}

.access-list {
  margin-bottom: 20px;
}

.access-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.action-button {
  background-color: #0078ae;
  color: rgb(255, 255, 255);
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
  font-size: 14px;
}

.action-button:hover,
.trash-can:hover,
.edit-button:hover {
  opacity: 0.7;
}

.action-button .fas {
  margin: 0;
  padding: 0;
}

.explanation-section {
  display: flex;
  flex-direction: column;
  gap: 5px; /* Small gap between items */
  font-size: 12px; /* Smaller font for compact design */
  color: #333; /* General text color */
  margin-bottom: 13px; /* Small bottom margin for spacing */
  width: 100%; /* Ensure it takes up the full width of its container */
  max-width: 100%; /* Prevent it from exceeding the modal's width */
  box-sizing: border-box; /* Include padding/borders in the element's width */
}

.explanation-item {
  display: flex;
  align-items: center;
  line-height: 1.2; /* Reduce line height to make it more compact */
  width: 100%; /* Ensure each item also takes up full width */
  padding-bottom: 4px;
}

.explanation-item i {
  margin-right: 5px; /* Space between icon and text */
  font-size: 14px; /* Smaller icon size */
}

.lock-icon {
  color: #dc3545; /* Red color for locked icon */
}

.copy-icon {
  color: #0078ae; /* Blue color for copy icon */
}

.cog-icon {
  color: #d6336c; /* Dark pink color for cog icon */
}

.talk-to-agent-button {
  background-color: #0078ae;
  color: rgb(255, 255, 255);
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
}

.talk-to-agent-button:hover {
  background-color: #005f8d;
}

.badge-free {
  background-color: #218838; /* Green color */
  color: rgb(255, 255, 255);
  font-size: 12px;
  font-weight: bold;
  padding: 3px 7px;
  border-radius: 12px;
  margin-left: 10px; /* Space between text and badge */
  text-transform: uppercase;
}

.modal-title {
  margin-bottom: 20px;
  font-size: 18px;
  color: #0078ae;
}

.input-group {
  margin-bottom: 15px;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.input-group input,
.input-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  box-sizing: border-box;
}

.input-group textarea {
  resize: vertical;
}

.mobile-login-button{
  background-color: #28a745; /* Green background for login button */
  color: rgb(255, 255, 255);
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0px 11px 21px rgba(0, 0, 0, 0.2);
}

.modal-green-button {
  background-color: #28a745; /* Green background for login button */
  color: rgb(255, 255, 255);
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0px 11px 21px rgba(0, 0, 0, 0.2);
}

.modal-green-button:hover {
  background-color: #218838;
}

.edit-itinerary {
  z-index: 1101; /* Ensure the content inside is also on top */
}

.message-ta {
  z-index: 1101; /* Ensure the content inside is also on top */
}

.modal-x-button {
  background-color: #dc3545 !important; /* Red */
  color: white !important;
  padding: 10px 20px !important;
  border: none !important;
  border-radius: 5px !important;
  cursor: pointer !important;
  font-size: 16px !important;
  transition: background-color 0.3s ease !important;
}

.modal-plus-button {
  background-color: #28a745 !important; /* Green */
  color: white !important;
  padding: 10px 20px !important;
  border: none !important;
  border-radius: 5px !important;
  cursor: pointer !important;
  font-size: 16px !important;
  transition: background-color 0.3s ease !important;
}

.center-me {
  text-align: center;
}

.remove-underline {
  text-decoration: none;
}

.margin-three {
  margin: 3px;
}

.legal-links {
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
}

.legal-links a {
  color: #0078ae; /* Adjust this to match your theme */
  text-decoration: none;
  font-size: 14px;
}

.legal-links a:hover {
  text-decoration: underline;
}

.modal-preview {
  display: flex; /* Enables flexbox layout */
  flex-direction: column; /* Ensures children are stacked vertically */
  justify-content: center; /* Centers content vertically */
  align-items: center; /* Centers content horizontally */
  text-align: center; /* Ensures the text itself is centered */
  height: 100%; /* Ensures the modal takes up the full height if needed */
  padding: 10px; /* Optional padding for spacing */
}

.profile-button {
  padding: 12px 18px;
  font-size: 16px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.profile-button:hover {
  transform: scale(1.05);
}

.profile-button.register {
  background-color: #28a745; /* Green */
  color: white;
  box-shadow: 0 0 10px rgba(40, 167, 69, 0.6);
}

.profile-button.register:hover {
  box-shadow: 0 0 15px rgba(40, 167, 69, 0.9);
}

.profile-button.register:active {
  animation: pulseGreen 0.3s;
}

@keyframes pulseGreen {
  0% { box-shadow: 0 0 10px rgba(40, 167, 69, 0.9); }
  50% { box-shadow: 0 0 20px rgba(40, 167, 69, 0.6); }
  100% { box-shadow: 0 0 10px rgba(40, 167, 69, 0.9); }
}

.profile-button.upgrade {
  background-color: #ffcc00; /* Yellow */
  color: black;
}

.profile-button.subscribed {
  background-color: #ff4d6d; /* Soft Red */
  color: white;
}

.profile-button.subscribed:hover {
  box-shadow: 0 0 10px rgba(255, 77, 109, 0.7);
}
