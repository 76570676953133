.about-container {
    max-width: 800px;
    margin: 40px auto;
    padding: 30px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  }
  
  .about-content {
    text-align: left;
  }
  
  .navigation-buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .nav-button {
    background-color: #0078ae;
    color: white;
    padding: 10px 16px;
    font-size: 16px;
    border-radius: 6px;
    border: none;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
  }
  
  .nav-button:hover {
    background-color: #005f8d;
  }
  
  h1 {
    text-align: center;
    font-size: 32px;
    color: #0078ae;
  }
  
  h2 {
    font-size: 24px;
    color: #0078ae;
    margin-top: 20px;
  }
  
  p {
    font-size: 16px;
    color: #333;
    line-height: 1.6;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  ul li {
    font-size: 16px;
    padding: 8px 0;
    display: flex;
    align-items: center;
  }
  
  .email-link {
    color: #0078ae;
    font-weight: bold;
    text-decoration: none;
  }
  
  .email-link:hover {
    text-decoration: underline;
  }
  
  .support-links {
    display: flex;
    gap: 15px;
    margin-top: 20px;
  }
  
  .email-button {
    background-color: #0078ae;
    color: white;
    padding: 12px 18px;
    font-size: 16px;
    border-radius: 6px;
    text-decoration: none;
    text-align: center;
  }
  
  .email-button:hover {
    background-color: #005f8d;
  }
  