/* src/components/TermsAndConditions.css */
.terms-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh; /* Ensure it takes the full viewport height */
    background-color: #0078ae; /* Set the background color */
    padding: 20px;
}

.terms-content {
    max-width: 800px;
    width: 100%;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    box-sizing: border-box;
    text-align: center; /* Center the text content */
}

.terms-content h1 {
    font-size: 28px;
    margin-bottom: 15px;
    color: #0078ae;
}

.terms-content h2 {
    font-size: 22px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #005f8d;
}

.terms-content p {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 15px;
    color: #333;
}

.terms-content a {
    color: #FFFFFF;
    text-decoration: none;
}

.terms-content a:hover {
    text-decoration: underline;
}

.back-button {
    display: inline-block;
    margin-bottom: 20px;
    background-color: #005f8d;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    font-size: 16px;
    transition: background-color 0.3s ease-in-out;
}

.back-button:hover {
    background-color: #004466;
}
